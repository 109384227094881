import React, { useEffect, useRef } from "react";

export default function Drawing({ drawingSize, endpoints, direction, area }) {
  const canvas = useRef();
  const ctxRef = useRef(null);

  // initialize the canvas context
  useEffect(() => {
    // dynamically assign the width and height to canvas
    const canvasEle = canvas.current;
    canvasEle.width = canvasEle.clientWidth;
    canvasEle.height = canvasEle.clientHeight;

    // get context of the canvas
    const context = canvasEle.getContext("2d");
    ctxRef.current = context;
  }, []);

  useEffect(() => {
    const ctx = ctxRef.current;

    const drawLine = (info, style = {}) => {
      const { x, y, x1, y1 } = info;
      const { color = "black", width = 1 } = style;

      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x1, y1);
      ctx.strokeStyle = color;
      ctx.lineWidth = width;
      ctx.stroke();
    };

    ctx.clearRect(0, 0, drawingSize[0], drawingSize[1]);
    drawLine(
      {
        x: endpoints[0].x - 5,
        y: endpoints[0].y - 5,
        x1: endpoints[0].x + 5,
        y1: endpoints[0].y + 5,
      },
      { width: "2" }
    );
    drawLine(
      {
        x: endpoints[0].x + 5,
        y: endpoints[0].y - 5,
        x1: endpoints[0].x - 5,
        y1: endpoints[0].y + 5,
      },
      { width: "2" }
    );

    for (let i = 1; i < endpoints.length; i++) {
      drawLine(
        {
          x: endpoints[i - 1].x,
          y: endpoints[i - 1].y,
          x1: endpoints[i].x,
          y1: endpoints[i].y,
        },
        { width: "2" }
      );
    }
  }, [direction, drawingSize, endpoints]);

  return (
    <div className="drawing">
      <div className="row">
        <canvas
          ref={canvas}
          style={{
            width: drawingSize[0],
            height: drawingSize[1],
            border: "1px solid",
            margin: "auto",
            display: "block",
          }}
        />
      </div>
      <div className="row">
        <div>Area: {area} &#13217;</div>
      </div>
    </div>
  );
}
