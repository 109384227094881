import React, { useState } from "react";

export default function AddLine({ addError, handleAddClicked }) {
  const [length, setLength] = useState(0);

  function handleChange(value) {
    setLength(value);
  }

  function addClicked() {
    handleAddClicked(length);
    document.getElementById("lengthInput").value = "";
    setLength(0);
  }

  return (
    <>
      <p className="add-section">Enter the length</p>
      <input
        className="length-input"
        id="lengthInput"
        type="number"
        placeholder="Length"
        onChange={(e) => handleChange(e.target.value)}
      />
      <br />
      <p className="error-line">{addError}</p>
      <button className="btn-primary" onClick={() => addClicked()}>
        Add
      </button>
      <br />
      <br />
    </>
  );
}
