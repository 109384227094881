import React, { useState } from "react";

export default function DirectionArrows({
  handleSetDirection,
  isAdded,
  handleSetIsAdded,
}) {
  const [isUpActive, setIsUpActive] = useState(false);
  const [isDownActive, setIsDownActive] = useState(false);
  const [isLeftActive, setIsLeftActive] = useState(false);
  const [isRightActive, setIsRightActive] = useState(false);

  function changeDirection(value) {
    handleSetIsAdded(false);
    handleSetDirection(value);
    if (value === "UP") {
      setIsUpActive(true);
      setIsDownActive(false);
      setIsLeftActive(false);
      setIsRightActive(false);
    } else if (value === "DOWN") {
      setIsUpActive(false);
      setIsDownActive(true);
      setIsLeftActive(false);
      setIsRightActive(false);
    } else if (value === "LEFT") {
      setIsUpActive(false);
      setIsDownActive(false);
      setIsLeftActive(true);
      setIsRightActive(false);
    } else if (value === "RIGHT") {
      setIsUpActive(false);
      setIsDownActive(false);
      setIsLeftActive(false);
      setIsRightActive(true);
    }
  }

  return (
    <div className="direction-arrows">
      <p>Choose direction</p>
      <div className="directions">
        <div className="arrows">
          <a
            href="/#"
            className={isUpActive && !isAdded ? "arrow-clicked" : "arrow"}
            id={"UP"}
            onClick={() => changeDirection("UP")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              fill="currentColor"
              class="bi bi-arrow-up-square"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
              />
            </svg>
          </a>
        </div>
        <div className="arrows">
          <a
            href="/#"
            className={isLeftActive && !isAdded ? "arrow-clicked" : "arrow"}
            id={"LEFT"}
            style={{ marginRight: "50px" }}
            onClick={() => changeDirection("LEFT")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              fill="currentColor"
              class="bi bi-arrow-left-square"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
              />
            </svg>
          </a>
          <a
            href="/#"
            className={isRightActive && !isAdded ? "arrow-clicked" : "arrow"}
            id={"RIGHT"}
            onClick={() => changeDirection("RIGHT")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              fill="currentColor"
              class="bi bi-arrow-right-square"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm4.5 5.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
              />
            </svg>
          </a>
        </div>
        <div className="arrows">
          <a
            href="/#"
            className={isDownActive && !isAdded ? "arrow-clicked" : "arrow"}
            id={"DOWN"}
            onClick={() => changeDirection("DOWN")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              fill="currentColor"
              class="bi bi-arrow-down-square"
              viewBox="0 0 16 16"
              onClick={() => changeDirection("DOWN")}
            >
              <path
                fill-rule="evenodd"
                d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
}
