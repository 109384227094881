import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState } from "react";
import "./App.css";
import Drawing from "./Drawing";
import DirectionArrows from "./DirectionArrows";
import AddLine from "./AddLine";
import OtherButtons from "./OtherButtons";

function App() {
  const width = 800 < window.innerWidth ? 800 : window.innerWidth;
  const height = width === window.innerWidth ? 300 : 500;
  const [drawingSize] = useState([width, height]);
  const margin = 50;
  const smallestLength = Math.min(...drawingSize);
  const [showDrawing, setShowDrawing] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [endpoints, setEndpoints] = useState([{ x: 0, y: 0, val: 0, dir: "" }]);
  const [drawingEndpoints, setDrawingEndpoints] = useState([]);
  const [direction, setDirection] = useState("");
  const [area, setArea] = useState(0);
  const [scale, setScale] = useState(0);

  const [isComplete, setIsComplete] = useState(false);
  const [addError, setAddError] = useState("");
  const [completeOutput, setCompleteOutput] = useState("");

  const [minX, setMinX] = useState(0);
  const [minY, setMinY] = useState(0);
  const [maxX, setMaxX] = useState(0);
  const [maxY, setMaxY] = useState(0);

  useEffect(() => {
    let total = 5;
    if (endpoints !== undefined) {
      let xList = endpoints.map((e) => e.x);
      let yList = endpoints.map((e) => e.y);
      let minX = Math.min(...xList);
      let minY = Math.min(...yList);
      let maxX = Math.max(...xList);
      let maxY = Math.max(...yList);
      total =
        Math.max(maxX - minX, maxY - minY) === 0
          ? 5
          : Math.max(maxX - minX, maxY - minY);
      setMinX(minX);
      setMinY(minY);
      setMaxX(maxX);
      setMaxY(maxY);
    }
    let newScale = (smallestLength - margin * 2) / total;
    setScale(newScale);
  }, [endpoints, smallestLength]);

  useEffect(() => {
    let drawEndpoints = [];
    if (endpoints !== undefined) {
      let offsetX = drawingSize[0] / 2 - ((minX + maxX) * scale) / 2;
      let offsetY = drawingSize[1] / 2 - ((minY + maxY) * scale) / 2;
      endpoints.forEach((element) => {
        drawEndpoints = drawEndpoints.concat({
          x: element.x * scale + offsetX,
          y: element.y * scale + offsetY,
        });
      });
      setDrawingEndpoints(drawEndpoints);
    }
  }, [endpoints, drawingSize, scale, minX, maxX, minY, maxY]);

  function handleStartClicked() {
    let newEndpoints = endpoints.concat({ x: 0, y: 0, val: 0, dir: "" });
    setEndpoints(newEndpoints);
    setShowDrawing(true);
  }

  function handleSetDirection(value) {
    setDirection(value);
  }

  function polygonArea(endpoints, n) {
    // Initialize area
    let area = 0.0;

    // Calculate value of shoelace formula
    let j = n - 1;
    for (let i = 0; i < n; i++) {
      area +=
        (endpoints[j].x + endpoints[i].x) * (endpoints[j].y - endpoints[i].y);

      // j is previous vertex to i
      j = i;
    }

    // Return absolute value
    let returnArea = Math.abs(area / 2.0);
    return Math.round(returnArea * 100) / 100;
  }

  function handleAddClicked(value) {
    if (value !== 0 && direction !== "" && !isComplete) {
      let length = parseInt(value);
      let newEndpoints;
      if (direction === "UP") {
        newEndpoints = endpoints.concat({
          x: endpoints[endpoints.length - 1].x,
          y: endpoints[endpoints.length - 1].y - length,
          val: length,
          dir: direction,
        });
      } else if (direction === "DOWN") {
        newEndpoints = endpoints.concat({
          x: endpoints[endpoints.length - 1].x,
          y: endpoints[endpoints.length - 1].y + length,
          val: length,
          dir: direction,
        });
      } else if (direction === "LEFT") {
        newEndpoints = endpoints.concat({
          x: endpoints[endpoints.length - 1].x - length,
          y: endpoints[endpoints.length - 1].y,
          val: length,
          dir: direction,
        });
      } else if (direction === "RIGHT") {
        console.log("r", endpoints);
        newEndpoints = endpoints.concat({
          x: endpoints[endpoints.length - 1].x + length,
          y: endpoints[endpoints.length - 1].y,
          val: length,
          dir: direction,
        });
      }
      console.log("set");
      console.log(newEndpoints);
      setEndpoints(newEndpoints);
      setAddError("");
      setDirection("");
      setIsAdded(true);
    } else {
      if (value === 0 && direction === "") {
        setAddError("Length Not Entered and Direction Not Clicked");
      } else if (value === 0) {
        setAddError("No Length Entered");
      } else if (direction === "") {
        setAddError("No Direction Clicked");
      }
    }
  }

  function handleEndClicked() {
    let newEndpoints;
    let n;
    let x = 0;
    let y = 0;
    if (endpoints !== undefined) {
      n = endpoints.length;
      if (endpoints[n - 1].dir === "UP" || endpoints[n - 1].dir === "DOWN") {
        x = 0;
        y = endpoints[n - 1].y;
        let length = endpoints[n - 1].x;
        let dir = length >= 0 ? "LEFT" : "RIGHT";
        newEndpoints = endpoints.concat({
          x: x,
          y: y,
          val: length,
          dir: dir,
        });
      } else if (
        endpoints[n - 1].dir === "LEFT" ||
        endpoints[n - 1].dir === "RIGHT"
      ) {
        x = endpoints[n - 1].x;
        y = 0;
        let length = endpoints[n - 1].y;
        let dir = length >= 0 ? "UP" : "DOWN";
        newEndpoints = endpoints.concat({
          x: x,
          y: y,
          val: Math.abs(length),
          dir: dir,
        });
      }

      if (newEndpoints !== undefined) {
        if (newEndpoints[n].dir === "UP" || newEndpoints[n].dir === "DOWN") {
          let length = newEndpoints[n].x;
          let dir = length >= 0 ? "LEFT" : "RIGHT";
          newEndpoints = newEndpoints.concat({
            x: 0,
            y: 0,
            val: Math.abs(length),
            dir: dir,
          });
        } else if (
          newEndpoints[n].dir === "LEFT" ||
          newEndpoints[n].dir === "RIGHT"
        ) {
          let length = newEndpoints[n].y;
          let dir = length >= 0 ? "UP" : "DOWN";
          newEndpoints = newEndpoints.concat({
            x: 0,
            y: 0,
            val: Math.abs(length),
            dir: dir,
          });
        }
        setEndpoints(newEndpoints);

        const polyArea = polygonArea(newEndpoints, newEndpoints.length);
        setArea(polyArea);
      }
      setIsComplete(true);
      setAddError("");
      setCompleteOutput("Completed!");
      setDirection("");
    } else {
      setIsComplete(false);
      setCompleteOutput("Unable to Complete! Please Press Back to Restart.");
    }
  }

  function handleBackClicked() {
    setShowDrawing(false);
    setDirection("");
    setAddError("");
    setCompleteOutput("");
    setArea(0);
    setEndpoints([{ x: 0, y: 0, val: 0, dir: "" }]);
    setDrawingEndpoints([]);
  }

  function handleSetIsAdded(value) {
    setIsAdded(value);
  }

  function main() {
    if (showDrawing) {
      return (
        <>
          <div className="drawing-page">
            <div className="container">
              <div className="row">
                <h1>House Drawing</h1>
                <Drawing
                  drawingSize={drawingSize}
                  endpoints={drawingEndpoints}
                  direction={direction}
                  area={area}
                />
              </div>
              <div className="row buttons">
                <div className="col-md-2"></div>
                <div className="col-md-5">
                  <div className="row">
                    <h3>Add New Length</h3>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-6">
                      <div className="direction">
                        <DirectionArrows
                          handleSetDirection={handleSetDirection}
                          isAdded={isAdded}
                          handleSetIsAdded={handleSetIsAdded}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-6">
                      <AddLine
                        addError={addError}
                        handleAddClicked={handleAddClicked}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="row">
                    <h3>Complete</h3>
                  </div>
                  <OtherButtons
                    isComplete={isComplete}
                    completeOutput={completeOutput}
                    handleEndClicked={handleEndClicked}
                    handleBackClicked={handleBackClicked}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="start-page">
            <h1>House Plan</h1>
            <button
              style={{ fontSize: "1.5em", padding: "0 50px" }}
              onClick={() => handleStartClicked()}
            >
              Start
            </button>
          </div>
        </>
      );
    }
  }

  return <div className="App">{main()}</div>;
}

export default App;
