import React from "react";

export default function OtherButtons({
  isComplete,
  completeOutput,
  handleBackClicked,
  handleEndClicked,
}) {
  return (
    <>
        <p>This will complete the level, and calculate the area.</p>
        <p className={isComplete ? "success-line" : "error-line"}>
          {completeOutput}
        </p>
        <button className="btn-primary" onClick={handleEndClicked}>
          Complete
        </button>
        <br />
        <br />
        <h3>Back</h3>
        <p>Press "Back" to go back to the start page and restart.</p>
        <button className="btn-primary" onClick={handleBackClicked}>
          Back
        </button>
    </>
  );
}
